export const postToParentCallback =
  (type: string) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (payload?: any): void => {
    const targetOrigin = new URLSearchParams(window.location.search).get('origin');

    if (!targetOrigin) {
      console.error(
        'No origin provided, unable to post message to iframe parent. Please refer to the iframe integration doc here: https://docs.arcadia.com/docs/integrate-connect#iframe-events to begin receiving callbacks. '
      );
      return;
    }

    // We need to serialize as a plain object because Error objects
    // are not supported in postMessages in Safari.
    const sanitizedPayload = payload ? JSON.parse(JSON.stringify(payload)) : payload;

    const message = { type, payload: sanitizedPayload };
    window.parent.postMessage(message, targetOrigin);
  };
