import i18n, { TFunction, KeyPrefix, getFixedT } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './resources';

export const resources = {
  en,
} as const;
export const defaultNS = 'connect';

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export { i18n };

/**
 * Note - the types below will run into issues if there is more than one
 * namespace.
 */
type DefaultNamespace = typeof defaultNS;

export const copyFor = <Prefix extends KeyPrefix<DefaultNamespace> & string>(
  prefix: Prefix
): TFunction<DefaultNamespace, Prefix> => getFixedT(null, 'connect', prefix);
