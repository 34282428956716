import { AppWrapper } from '@client/providers/app-wrapper';
import { StandaloneWrapper } from './standalone-wrapper';

export function App(): JSX.Element {
  return (
    <AppWrapper>
      <StandaloneWrapper />
    </AppWrapper>
  );
}
