import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import Rive from '@rive-app/react-canvas-lite';

export const VerifyingText = styled(Text)`
  text-align: center;
  white-space: pre-wrap;
`;

export const LoadingAnimation = styled(Rive)`
  height: 40vh;
  width: 500px;
  max-width: 90vw;
  margin: 0 auto;
`;
