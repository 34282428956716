import styled from 'styled-components';
import { Radio } from '@arcadiapower/shrike';

export const ProviderOption = styled(Radio)`
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
`;

export const ProviderOptionWrapper = styled.div`
  width: 100%;
  border-bottom: ${props => props.theme.colors.background.tertiary} solid 1px;
`;

export const StyledSpan = styled.span`
  font-weight: bolder;
`;

export const ProviderContentWrapper = styled.div<{
  errorPresent: boolean;
  providersPresent: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${props =>
    !props.providersPresent &&
    `
    align-items: center;
    justify-content: center;
  `}
  ${props => !props.errorPresent && !props.providersPresent && `margin-top: 29px`}
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  align-items: center;
  padding-top: 24px;
`;
