import success from '@client/assets/icons/success.svg';
import timeout from '@client/assets/icons/timeout.svg';
import { Provider } from '@schema/schemas';
import { FlexCrate } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { ContentSectionFullHeight, ContentSection } from '@client/components/connect-container';
import { ConclusionText, Status } from './conclusion.style';

export type Props = {
  status?: string;
  provider: Provider;
  organizationName: string;
};

export const Conclusion = ({ status, provider, organizationName }: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.conclusion' });
  const icon = status === 'LOGIN_SUCCESS' ? success : timeout;

  const getConclusionText = (): string => {
    if (provider.isThirdPartyPortalSupported) return t('thirdPartyPortal');
    if (status === 'LOGIN_SUCCESS') {
      return t('loginSuccess', { providerName: provider.name });
    }
    return t('loginTimedOut', { providerName: provider.name, organizationName });
  };

  return (
    <ContentSectionFullHeight>
      <ContentSection centerContentVertically centerContentHorizontally>
        <FlexCrate flexDirection="column" alignItems="center">
          <Status src={icon} />
          <FlexCrate flexDirection="column" alignItems="center" gap="32px">
            <ConclusionText data-testid="conclusion-text">{getConclusionText()}</ConclusionText>
          </FlexCrate>
        </FlexCrate>
      </ContentSection>
    </ContentSectionFullHeight>
  );
};
