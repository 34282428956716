import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Status = styled(SVG)`
  height: 56px;
  width: 56px;
  margin-bottom: 32px;
`;

export const ConclusionText = styled(Text)`
  text-align: center;
  white-space: pre-wrap;
`;
