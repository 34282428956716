import { assign, createMachine, sendParent } from 'xstate';
import {
  Provider,
  AcquisitionTemplateOutput,
  assertNotNullable,
  CredentialResponse,
  Organization,
  FormSchema,
  SmtMetersInput,
} from '@arc-connect/schema';
import { TRPCClient } from '@client/utils/trpc';
import { Config } from '@client/types/config';
import { UserError } from '@client/config/errors';
import { trackEvent, TrackEvents } from '@client/utils/analytics';
import { CredentialsContext, CredentialsEvent, CredentialsServices } from './types';

type CredentialsMachineParams = {
  acquisitionTemplate: AcquisitionTemplateOutput;
  client: TRPCClient;
  provider: Provider;
  credentials?: CredentialResponse;
  organization: Organization;
  config: Config;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const credentialsMachine = ({
  acquisitionTemplate,
  provider,
  client,
  credentials,
  organization,
  config,
}: CredentialsMachineParams) =>
  createMachine(
    {
      tsTypes: {} as import('./credentials.typegen').Typegen0,
      predictableActionArguments: true,
      schema: {
        context: {} as CredentialsContext,
        events: {} as CredentialsEvent,
        services: {} as CredentialsServices,
      },
      context: {
        acquisitionTemplate,
        credentials,
        contracts: [],
        provider,
        challengeStatus: undefined,
        credentialsError: undefined,
        returnedVerificationMethods: undefined,
        selectedVerificationMethod: undefined,
        verificationCode: undefined,
        templateFields: config.updateTokenInfo
          ? FormSchema.parse(config.updateTokenInfo)
          : undefined,
        organization,
        config,
        isResubmission: false,
        isMethodResubmission: false,
        meters: undefined,
      },
      id: 'credentials',
      initial: 'initializing',
      states: {
        initializing: {
          always: [
            { target: 'credentialsForm.submitting', cond: 'shouldSkipCredentialsForm' },
            { target: 'credentialsForm', cond: 'shouldShowCredentialSubmission' },
            { target: 'metersForm', cond: 'shouldShowMeterSubmission' },
          ],
        },
        credentialsForm: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT_CREDENTIALS: {
                  actions: ['saveCredentialsSubmission', 'saveCorrelationId'],
                  target: 'submitting',
                },
                PREVIOUS: {
                  actions: 'sendPreviousToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
            submitting: {
              invoke: {
                id: 'submitCredentials',
                src: 'submitCredentials',
                onDone: {
                  target: 'success',
                  actions: [
                    'sendCredentialsToParent',
                    'saveCredentials',
                    'sendOnCredentialsSubmitted',
                    'clearCredentialsError',
                  ],
                },
                onError: {
                  target: 'idle',
                  actions: 'saveError',
                },
              },
            },
            success: {
              type: 'final',
            },
          },
          onDone: [
            {
              target: 'polling',
              cond: 'supportsCredentialValidation',
            },
            { target: 'exit' },
          ],
        },
        metersForm: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT_METER: {
                  actions: ['saveMeters'],
                  target: 'submitting',
                },
                PREVIOUS: {
                  actions: 'sendPreviousToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
            submitting: {
              invoke: {
                id: 'submitMeters',
                src: 'submitMeters',
                onDone: {
                  target: 'success',
                  actions: ['clearCredentialsError'],
                },
                onError: {
                  target: 'idle',
                  actions: 'saveError',
                },
              },
            },
            success: {
              type: 'final',
            },
          },
          onDone: [{ target: 'metersConclusion' }],
        },
        metersConclusion: {
          initial: 'idle',
          states: {
            idle: {
              entry: ['clearMeters'],
              on: {
                PREVIOUS: {
                  actions: 'sendPreviousToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
          },
        },
        polling: {
          initial: 'fetching',
          after: {
            TIME_OUT: { target: 'polling.timedOut', cond: 'credentialsTimedOut' },
          },
          states: {
            fetching: {
              invoke: {
                id: 'checkChallenge',
                src: 'checkChallenge',
                onDone: [
                  {
                    actions: [
                      'saveChallengeStatus',
                      'saveVerificationMethods',
                      'saveIsResubmission',
                      'saveCredentialStatus',
                    ],
                    target: 'checking',
                  },
                ],
                // If there's an error fetching the challenge status, it's
                // probably a network issue. We wait and then try again
                // after the interval
                onError: 'waiting',
              },
            },
            checking: {
              always: [
                { target: 'verifiedTransitioning', cond: 'credentialsValid' },
                {
                  target: '#credentials.credentialsForm',
                  cond: 'credentialsIncorrect',
                  actions: 'saveCredentialsError',
                },
                {
                  target: '#credentials.mfaVerificationCodeForm',
                  cond: 'mfaMethodSelected',
                },
                { target: '#credentials.mfaUnsupported', cond: 'credentialMFAUnsupported' },
                {
                  target: '#credentials.mfaVerificationMethodsForm',
                  cond: 'mfaMethodsReturned',
                  actions: ['sendMfaMethodsReturnedEvent'],
                },
                {
                  target: '#credentials.mfaFailure',
                  cond: 'mfaCodeIncorrect',
                  actions: ['sendMfaFailureToParent'],
                },
                { target: 'waiting' },
              ],
            },
            waiting: {
              after: {
                POLLING_INTERVAL: { target: 'fetching' },
              },
            },
            timedOut: { type: 'final' },
            verifiedTransitioning: { after: { TRANSITION_DELAY: 'verified' } },
            verified: { type: 'final' },
          },
          onDone: 'exit',
        },
        mfaVerificationMethodsForm: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT_METHOD: {
                  actions: ['saveMfaVerificationMethod', 'sendMfaMethodSelectedEvent'],
                  target: 'submitting',
                },
                RESELECT_PROVIDER: {
                  actions: 'sendReselectProviderToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
            submitting: {
              invoke: {
                id: 'submitMfaVerificationMethod',
                src: 'submitMfaVerificationMethod',
                onDone: {
                  target: 'success',
                  actions: ['saveChallengeStatus', 'clearCredentialsError'],
                },
                onError: {
                  target: 'idle',
                  actions: 'saveError',
                },
              },
            },
            success: {
              type: 'final',
            },
          },
          onDone: { target: 'polling.fetching' },
        },
        mfaVerificationCodeForm: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT_CODE: {
                  actions: 'saveMfaVerificationCode',
                  target: 'submitting',
                },
                RESEND_CODE: {
                  target: '#credentials.mfaVerificationMethodsForm.submitting',
                  actions: 'setCodeResubmission',
                },
                RESELECT_METHOD: {
                  target: '#credentials.mfaVerificationMethodsForm',
                  actions: 'setCodeResubmission',
                },
              },
            },
            submitting: {
              invoke: {
                id: 'submitMfaVerificationCode',
                src: 'submitMfaVerificationCode',
                onDone: {
                  target: 'success',
                  actions: ['saveChallengeStatus', 'clearCredentialsError'],
                },
                onError: {
                  target: 'idle',
                  actions: 'saveError',
                },
              },
            },
            success: {
              type: 'final',
            },
          },
          onDone: { target: 'polling.fetching' },
        },
        mfaUnsupported: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                RESELECT_PROVIDER: {
                  actions: 'sendReselectProviderToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
          },
        },
        mfaFailure: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                RESELECT_PROVIDER: {
                  actions: 'sendReselectProviderToParent',
                  cond: 'canGoBackToProvider',
                },
              },
            },
          },
        },
        exit: {
          type: 'final',
          entry: 'sendDoneToParent',
        },
      },
    },
    {
      actions: {
        saveChallengeStatus: assign((_context, event) => ({
          challengeStatus: event.data.challenge.eventType,
        })),
        saveCredentialStatus: assign((_context, event) => {
          return event.data.challenge.eventType === 'LOGIN_FAILURE'
            ? {
                credentials: {
                  status: event.data.challenge.status,
                  statusDetail: event.data.challenge.statusDetail,
                  correlationId: event.data.challenge.correlationId,
                  username: _context.credentials?.username,
                  challengeId: _context.credentials?.challengeId || null,
                  entityId: event.data.challenge.credentialId,
                },
              }
            : {};
        }),
        saveVerificationMethods: assign((context, event) => {
          return event.data.challenge.eventType === 'MFA_CHALLENGE_METHOD'
            ? { returnedVerificationMethods: event.data.challenge.verificationMethods }
            : {};
        }),
        saveError: assign((_context, event) => ({
          credentialsError: event.data,
        })),
        saveCredentialsError: assign(_context => ({
          credentialsError: new UserError('incorrectCredentials'),
        })),
        clearCredentialsError: assign(_context => ({
          credentialsError: undefined,
        })),
        saveCredentialsSubmission: assign((_context, event) => ({
          templateFields: event.data,
        })),
        saveCorrelationId: assign((_context, event) => {
          return _context.config.allowSettingCorId
            ? {
                config: {
                  ..._context.config,
                  correlationId: event.data.correlationId,
                },
              }
            : {};
        }),
        saveCredentials: assign((_context, event) => ({
          credentials: event.data,
        })),
        saveMeters: assign((_context, event) => ({
          meters: event.data,
        })),
        clearMeters: assign(_context => ({
          meters: undefined,
        })),
        saveIsResubmission: assign((_context, event) => ({
          isResubmission:
            event.data.challenge.eventType === 'MFA_CHALLENGE_CODE' &&
            event.data.challenge.resubmission,
        })),
        saveMfaVerificationMethod: assign((_context, event) => ({
          selectedVerificationMethod: event.data,
        })),
        saveMfaVerificationCode: assign((_context, event) => ({
          verificationCode: event.data,
        })),
        sendCredentialsToParent: sendParent((_context, event) => ({
          type: 'CREDENTIALS_UPDATED',
          data: { credentials: event.data },
        })),
        sendPreviousToParent: sendParent({ type: 'PREVIOUS' }),
        sendReselectProviderToParent: sendParent({ type: 'RESELECT_PROVIDER' }),
        sendDoneToParent: sendParent(context => ({
          type: 'CREDENTIALS_DONE',
          data: { challengeStatus: context.challengeStatus },
        })),
        sendMfaFailureToParent: sendParent(() => ({
          type: 'MFA_FAILURE',
        })),
        sendOnCredentialsSubmitted: (context, event) => {
          context.config.callbacks?.onCredentialsSubmitted({
            utilityCredentialId: event.data.entityId,
          });
        },
        sendMfaMethodsReturnedEvent: context => {
          trackEvent(TrackEvents.MFA_METHODS_RETURNED, {
            mfaMethods: context.returnedVerificationMethods,
          });
        },
        sendMfaMethodSelectedEvent: context => {
          trackEvent(TrackEvents.MFA_METHOD_SELECTED, {
            selectedVerificationMethod: context.selectedVerificationMethod,
          });
        },
        setCodeResubmission: assign(_context => ({
          isMethodResubmission: true,
        })),
      },
      services: {
        submitCredentials: async context => {
          const { provider, credentials, config } = context;

          // The entity id is the id to update
          const credentialId =
            credentials?.entityId ||
            config.updateTokenInfo?.credentialId ||
            config.refreshTokenInfo?.credentialId;

          if (config.refreshTokenInfo) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return client.utilityCredential.refresh.mutate(credentialId!);
          }

          const templateFields = assertNotNullable(context.templateFields);

          const baseFields = {
            providerId: provider.id,
            uniqueId: context.organization.uniqueId,
            correlationId: config.correlationId,
            createdBy: config.createdBy,
          };

          // Special ConEd flow - we submit the account number only, which
          // requires a different data shape
          if ('accountNumber' in templateFields) {
            return client.utilityCredential.submitAccount.mutate({
              templateFields,
              ...baseFields,
            });
          }

          // Username/password flow
          const usernamePasswordFields = {
            templateFields,
            ...baseFields,
          };

          if (credentialId) {
            return client.utilityCredential.update.mutate({
              credentialId,
              ...usernamePasswordFields,
            });
          }
          return client.utilityCredential.create.mutate({
            ...usernamePasswordFields,
          });
        },
        submitMeters: async context => {
          const submission: SmtMetersInput = {
            ...assertNotNullable(context.meters),
            uniqueId: context.organization.uniqueId,
          };
          submission.providerId = context.provider.id;
          const result = await client.utilityCredential.submitMeter.mutate(submission);
          return result;
        },
        checkChallenge: async context => {
          const result = await client.challenge.getById.query(
            assertNotNullable(context.credentials?.challengeId)
          );
          return { challenge: result };
        },
        submitMfaVerificationMethod: async context => {
          const challengeId = assertNotNullable(context.credentials?.challengeId);
          const verificationMethod = assertNotNullable(context.selectedVerificationMethod);
          const isResubmission = context.isMethodResubmission;
          verificationMethod.isResubmission = isResubmission;
          const result = await client.challenge.submitMfaVerificationMethod.mutate({
            challengeId,
            verificationMethod,
          });
          return { challenge: result };
        },
        submitMfaVerificationCode: async context => {
          const challengeId = assertNotNullable(context.credentials?.challengeId);
          const verificationCode = assertNotNullable(context.verificationCode);
          const result = await client.challenge.submitMfaVerificationCode.mutate({
            challengeId,
            verificationCode,
          });
          return { challenge: result };
        },
      },
      guards: {
        shouldSkipCredentialsForm: context => !!context.config.refreshTokenInfo,
        supportsCredentialValidation: context =>
          context.provider.isRealTimeCredentialValidationSupported,
        credentialsValid: context => {
          const status = context.challengeStatus;
          return !!(status && status === 'LOGIN_SUCCESS');
        },
        credentialsIncorrect: context => {
          const status = context.challengeStatus;
          const isInvalidMfaResult = !!context.verificationCode;
          const credentialStatusDetail = context.credentials?.statusDetail;
          return (
            !!(status && status === 'LOGIN_FAILURE') &&
            !isInvalidMfaResult &&
            credentialStatusDetail !== 'MULTI_FACTOR_AUTH_NOT_IMPLEMENTED' &&
            credentialStatusDetail !== 'UNSUPPORTED_MULTI_FACTOR_AUTHENTICATION'
          );
        },
        credentialMFAUnsupported: context => {
          const status = context.challengeStatus;
          const isInvalidMfaResult = !!context.verificationCode;
          const credentialStatusDetail = context.credentials?.statusDetail;
          return (
            !!(status && status === 'LOGIN_FAILURE') &&
            !isInvalidMfaResult &&
            !!(
              credentialStatusDetail === 'MULTI_FACTOR_AUTH_NOT_IMPLEMENTED' ||
              credentialStatusDetail === 'UNSUPPORTED_MULTI_FACTOR_AUTHENTICATION'
            )
          );
        },
        credentialsTimedOut: context => {
          const status = context.challengeStatus;
          return status === 'NO_DATA' || status === 'PENDING' || !status;
        },
        mfaCodeIncorrect: context => {
          const status = context.challengeStatus;
          const isInvalidMfaResult = !!context.verificationCode;
          return !!(status && status === 'LOGIN_FAILURE') && isInvalidMfaResult;
        },
        mfaMethodsReturned: context => {
          const status = context.challengeStatus;
          return status === 'MFA_CHALLENGE_METHOD';
        },
        mfaMethodSelected: context => {
          const status = context.challengeStatus;
          return status === 'MFA_CHALLENGE_CODE';
        },
        canGoBackToProvider: context => {
          // If the provider was populated via the config or if they are in the update flow,
          // we shouldn't allow them to go back and change it
          return (
            !context.config.providerDetails &&
            !context.config.updateTokenInfo &&
            !context.config.refreshTokenInfo
          );
        },
        shouldShowCredentialSubmission: context => {
          return !context.config.isMeterSubmission;
        },
        shouldShowMeterSubmission: context => {
          return !!context.config.isMeterSubmission;
        },
      },
      delays: {
        TIME_OUT: 60_000,
        POLLING_INTERVAL: 500,
        TRANSITION_DELAY: 500,
      },
    }
  );
