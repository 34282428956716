import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { initErrorReporting } from './utils/error-reporting';
import '@arcadiapower/shrike/dist/shrike.css';

import '@client/config/copy/i18n';

initErrorReporting();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
