import { trackEvent, TrackEvents } from '@client/utils/analytics';
import { ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  ConnectContainerWrapper,
  StyledHeader,
  Title,
} from './connect-container.style';

export type Props = {
  previous?: () => void;
  title: string;
  children: ReactNode;
  testId?: string;
};

export const ConnectContainer = ({ previous, title, children, testId }: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.connectContainer' });

  const handlePrevious = useCallback(() => {
    if (previous) {
      trackEvent(TrackEvents.PREVIOUS_CLICKED, { currentScreen: title });
      previous();
    }
  }, [previous, title]);

  useEffect(() => {
    trackEvent(`Screen viewed - ${title}`);
  }, [title]);

  return (
    <ConnectContainerWrapper data-testid={testId}>
      <StyledHeader>
        <Title>{title}</Title>
        {!!previous && <BackButton onClick={handlePrevious} aria-label={t('backButton')} />}
      </StyledHeader>
      {children}
    </ConnectContainerWrapper>
  );
};
