import { Organization, Provider } from '@schema/schemas';
import { Button, FlexCrate, Radio, Text } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TrackEvents, trackEvent } from '@client/utils/analytics';
import { ContentSectionFullHeight, Footer } from '../connect-container';
import { StyledButtonContainer } from './select-data-access.style';
import { Form } from '../form';
import { OrganizationTerms } from '../organization-terms';

export type Props = {
  provider: Provider;
  organization: Organization;
  onSubmit: ({ isMeterSubmission }: { isMeterSubmission: boolean }) => void;
};

const smartMeterTexasUrl = 'https://www.smartmetertexas.com/aboutus';
export const SelectDataAccessComponent = ({
  provider,
  organization,
  onSubmit,
}: Props): JSX.Element => {
  const [isIntervalsSelected, setIsIntervalsSelected] = useState<boolean>(false);

  const { t } = useTranslation('connect', { keyPrefix: 'components.selectDataAccess' });

  const handleSubmit = () => {
    trackEvent(TrackEvents.DATA_ACCESS_METHOD_SELECTED, {
      method: isIntervalsSelected ? 'METER' : 'CREDENTIAL',
    });
    onSubmit({ isMeterSubmission: isIntervalsSelected });
  };
  return (
    <>
      <ContentSectionFullHeight withTopPadding={false}>
        <FlexCrate flexDirection="column" gap="32px">
          <Text textStyle="heading900" tag="h3" data-testid="providerName">
            {provider.name}
          </Text>
          <Text>{t('description')}</Text>
          <Form onSubmit={handleSubmit} id="dataAccessMethodForm">
            <FlexCrate gap="16px" flexDirection="column">
              <StyledButtonContainer isSelected={!isIntervalsSelected}>
                <Radio
                  name="monthly"
                  key="monthly"
                  checked={!isIntervalsSelected}
                  value={t('monthlyTitle')}
                  onChange={() => {
                    setIsIntervalsSelected(false);
                  }}
                >
                  <Text textStyle="heading500">{t('monthlyTitle')}</Text>
                  <Text textStyle="paragraph500">{t('monthlyDescription')}</Text>
                </Radio>
              </StyledButtonContainer>
              <StyledButtonContainer isSelected={isIntervalsSelected}>
                <Radio
                  name="intervals"
                  key="intervals"
                  checked={isIntervalsSelected}
                  value={t('intervalsTitle')}
                  onChange={() => {
                    setIsIntervalsSelected(true);
                  }}
                >
                  <Text textStyle="heading500">{t('intervalsTitle')}</Text>
                  <Text textStyle="paragraph500">
                    {t('intervalsDescription')}
                    <a href={smartMeterTexasUrl}>{t('smartMeterTexas')}</a>
                  </Text>
                </Radio>
              </StyledButtonContainer>
            </FlexCrate>
          </Form>
        </FlexCrate>
      </ContentSectionFullHeight>
      <Footer>
        <OrganizationTerms organization={organization} margin={{ bottom: '24px' }} />
        <Button
          form="dataAccessMethodForm"
          aria-label={t('submit')}
          type="submit"
          fullWidth={true}
          mode="primary"
        >
          {t('submit')}
        </Button>
      </Footer>
    </>
  );
};
