import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const TenantLogoImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 0.5px solid ${props => props.theme.colors.background.tertiary};
  outline: 2px solid white;
  position: relative;
`;

export const Dots = styled(SVG)`
  margin: 0px 6px;
`;

export const TenantLetterContainer = styled.div`
  // the background-color below is a recalculation of props.theme.colors.background.tertiary
  // with the alpha channel removed. The opacity in the above color was causing
  // an overlapping border issue.
  background-color: #d7dbda;

  border-radius: 50%;
  border: 0.5px solid ${props => props.theme.colors.background.tertiary};
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // this padding is necessary to balance out the right padding on the SVG containing the utility icon
  padding-left: 4.8px;
  border: 1px solid ${props => props.theme.colors.background.tertiary};
  border-radius: 100%;
  height: 64px;
  width: 64px;
`;
export const ArcadiaLogoContainer = styled.div`
  display: flex;
  position: relative;
  border: 1px solid ${props => props.theme.colors.background.tertiary};
  border-radius: 100%;
  height: 48px;
  width: 48px;
`;
export const ArcadiaLogo = styled(SVG)`
  alt: 'Developer Platform Logo';
  margin: auto;
  height: 40px;
  width: 40px;
`;
export const TenantLetter = styled(Text).attrs({
  textStyle: 'heading800',
})``;
