import { useTranslation } from 'react-i18next';
import { Text } from '@arcadiapower/shrike';
import { ContentSectionFullHeight } from '../connect-container';

export const MeterConclusion = (): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.smtMeterConclusion' });
  return (
    <ContentSectionFullHeight centerContentVertically>
      <Text textStyle="paragraph600">{t('description')}</Text>
    </ContentSectionFullHeight>
  );
};
