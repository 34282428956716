import { ThemeProvider } from 'styled-components';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, loggerLink } from '@trpc/client';
import { PropsWithChildren, useState } from 'react';
import { trpc } from '@client/utils/trpc';
import { theme } from '@client/config/theme';
import { DEBUG_TRPC, TRPC_URL } from '@client/config/constants';

import '@client/assets/style.css';
import { captureError } from '@client/utils/error-reporting';

type Props = PropsWithChildren<{}>;

export const AppWrapper = ({ children }: Props): JSX.Element => {
  const [queryClient] = useState(
    () =>
      // It's better to catch these errors on the react-query client than the TRPC
      // client because react-query automatically retries errors and we want to
      // avoid double reporting
      new QueryClient({
        queryCache: new QueryCache({
          onError: error => captureError({ error }),
        }),
        mutationCache: new MutationCache({
          onError: error => captureError({ error }),
        }),
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        loggerLink({
          enabled: opts => DEBUG_TRPC && opts.direction === 'down',
        }),
        httpBatchLink({
          url: TRPC_URL,
        }),
      ],
    })
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
};
