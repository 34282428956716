import { useMemo } from 'react';
import { Organization } from '@arc-connect/schema';
import { FlexCrate, Icon } from '@arcadiapower/shrike';
import arcadiaLogoMark from '@client/assets/icons/arcadia-logo-mark.svg';
import dots from '@client/assets/icons/dots.svg';
import {
  TenantLetterContainer,
  TenantLetter,
  Dots,
  ArcadiaLogoContainer,
  ArcadiaLogo,
  LogoContainer,
  TenantLogoImage,
} from './chained-logos.style';

export interface Props {
  className?: string;
  organization: Organization;
}

export const ChainedLogos = ({ className, organization }: Props): JSX.Element => {
  const renderTenantLogo = useMemo(() => {
    const { name: orgName, displayName } = organization;
    const imageUrl = organization._links?.logo?.href;
    const name = displayName || orgName || '';
    if (imageUrl) {
      return <TenantLogoImage src={imageUrl} alt={name} />;
    } else {
      return (
        <TenantLetterContainer aria-hidden={true}>
          <TenantLetter>{name.charAt(0)}</TenantLetter>
        </TenantLetterContainer>
      );
    }
  }, [organization]);

  return (
    <FlexCrate className={className} justifyContent="center" alignItems="center">
      {renderTenantLogo}
      <Dots src={dots} />
      <ArcadiaLogoContainer>
        <ArcadiaLogo src={arcadiaLogoMark} />
      </ArcadiaLogoContainer>
      <Dots src={dots} />
      <LogoContainer>
        <Icon icon="Utility" scale={4} />
      </LogoContainer>
    </FlexCrate>
  );
};
