import styled from 'styled-components';
import { Input } from '@arcadiapower/shrike';

export const SearchBar = styled(Input).attrs({ icon: 'UISearch' })<{ sticky?: boolean }>`
  ${props =>
    props.sticky &&
    `
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        padding: 32px 0 0;
        z-index: 3;
    `}
`;
