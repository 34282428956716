import { ENVIRONMENT, SENTRY_DSN, SENTRY_RELEASE, ENABLE_SENTRY } from '@client/config/constants';
import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import { TRPCClientError } from '@trpc/client';
import { sanitizeObjectDeep } from '@arcadiapower/warbler';
import { AppRouter } from './trpc';

export const initErrorReporting = (): void => {
  initSentry();
};

const initSentry = () => {
  if (!ENABLE_SENTRY) return;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: SENTRY_RELEASE,
    normalizeDepth: 5,
    integrations: [new ExtraErrorDataIntegration(), new Sentry.BrowserTracing()],
  });
};

const setSentryFingerprint = (scope: Sentry.Scope, error: unknown) => {
  if (error instanceof TRPCClientError) {
    const meta = (error as TRPCClientError<AppRouter>).shape?.meta;
    if (meta) {
      scope.setFingerprint(['{{ default }}', String(meta.type), String(meta.path)]);
    }
  }
};

export const captureError = ({ error, extras = {} }: { error: unknown; extras?: Extras }): void => {
  Sentry.withScope(scope => {
    const sanitizedExtras = sanitizeObjectDeep(extras, [
      /password/i,
      /verificationCode/,
      /Authorization/i,
      /token/i,
    ]);
    setSentryFingerprint(scope, error);
    scope.setExtras(sanitizedExtras);
    Sentry.captureException(error);
  });
};
