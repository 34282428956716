import { PropsWithChildren } from 'react';
import { Crate, CrateProps } from '@arcadiapower/shrike';

export type Props = PropsWithChildren<{
  onSubmit: () => void;
  margin?: CrateProps['margin'];
  testId?: string;
  id?: string;
}>;

export const Form = ({ onSubmit, children, margin, testId, id }: Props): JSX.Element => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Crate margin={margin}>
      <form onSubmit={handleSubmit} data-testid={testId} id={id}>
        {children}
      </form>
    </Crate>
  );
};
