import { FlexCrate, Icon, Spacer, Text, Tooltip } from '@arcadiapower/shrike';
import { ChainedLogos } from '@client/components/chained-logos';
import { Organization } from '@schema/schemas';
import { useTranslation } from 'react-i18next';

export type Props = {
  organization: Organization;
};

const TooltipContent = () => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.providerForm' });
  return (
    <FlexCrate flexFlow="column nowrap" gap="8px" padding="4px">
      <Text color="primaryInverse">{t('tooltipContent1')}</Text>
      <Text color="primaryInverse">{t('tooltipContent2')}</Text>
    </FlexCrate>
  );
};

export const ProviderHeader = ({ organization }: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.providerForm' });

  return (
    <div style={{ paddingTop: '32px', marginBottom: '-48px' }}>
      <ChainedLogos organization={organization} />
      <Text margin={{ top: '32px', bottom: '24px' }}>
        {t('headerText', { organizationName: organization.displayName || organization.name })}
      </Text>
      <FlexCrate flexDirection="row" justifyContent="center" margin={{ bottom: '-16px' }}>
        <Tooltip
          content={<TooltipContent />}
          tooltipId="select-utility-helper-text"
          label="select utility helper text"
          maxWidth={300}
        >
          <FlexCrate flexDirection="row" alignItems="center">
            <Icon icon="InfoCircleInverted" margin={{ right: '16px' }} color="secondary" />
            <Text color="secondary" textStyle="heading500">
              {t('tooltipText')}
            </Text>
          </FlexCrate>
        </Tooltip>
      </FlexCrate>
      <Spacer />
    </div>
  );
};
