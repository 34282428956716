import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const DisclaimerText = styled(Text).attrs({ color: 'secondary', textStyle: 'paragraph400' })`
  text-align: center;
`;

export const LockSpacer = styled.div`
  display: grid;
  grid-template-columns: auto 16px auto;
  grid-column-gap: 16px;
  align-items: center;
  :before,
  :after {
    background-color: ${props => props.theme.colors.background.tertiary};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
  }
`;
