import { copyFor } from './copy';
import { en } from './copy/resources';

export const getErrorCopy = copyFor('errors');

/**
 * UserErrors are errors intialized with error keys, so
 * their message is already translated
 */
export class UserError extends Error {
  constructor(errorKey: ErrorKey) {
    super(getErrorCopy(errorKey));
    this.name = 'UserError';
  }
}

type ErrorKey = keyof typeof en.connect.errors;
/**
 * First value: Regex that will match the error message
 * Second value: Key in config/resources/en.ts
 */

export const ERROR_MESSAGE_KEY_TUPLES: [RegExp, ErrorKey][] = [
  [/Unable to create duplicate credential./, 'duplicateCredentialsError'],
  [/Too many requests. Please try again later!/, 'rateLimitExceededError'],
];

const meterErrorKey = 'duplicateMetersError';
// This is a global function to parse API errors and return
// user-friendly strings, defaulting to the generic error
export const parseErrorMessage = (
  error: unknown,
  genericErrorOverride = '',
  isMeterSubmission = false
): string => {
  // UserErrors are already translated
  if (error instanceof UserError) return error.message;

  const errorMessage = error instanceof Error ? error.message : '';

  let userFriendlyError;
  for (const [messageSubstring, errorKey] of ERROR_MESSAGE_KEY_TUPLES) {
    if (messageSubstring.test(errorMessage)) {
      if (isMeterSubmission && errorKey === 'duplicateCredentialsError') {
        userFriendlyError = getErrorCopy(meterErrorKey);
      } else {
        userFriendlyError = getErrorCopy(errorKey);
      }
      break;
    }
  }
  return userFriendlyError || genericErrorOverride || getErrorCopy('genericError');
};
