import styled from 'styled-components';
import { Text, IconButton, Crate } from '@arcadiapower/shrike';

export const ConnectContainerWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const StyledHeader = styled.div<{
  backButton?: boolean;
}>`
  display: grid;
  grid-template-areas: 'back title close';
  grid-template-columns: 32px 1fr 32px;
  grid-template-rows: 32px;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};
  padding: 24px;
  height: 80px;
  gap: 10px;
`;

export const Title = styled(Text).attrs({ tag: 'h2', textStyle: 'heading700' })`
  flex-grow: 1;
  grid-row: 1;
  grid-column: title;
  text-align: center;
`;

export const BackButton = styled(IconButton).attrs({ icon: 'ChevronLeft' })`
  grid-column: back;
  grid-row: 1;
`;

export const ContentSection = styled(Crate)<{
  withTopPadding?: boolean;
  withBottomPadding?: boolean;
  centerContentVertically?: boolean;
  centerContentHorizontally?: boolean;
}>`
  padding-left: 24px;
  padding-right: 24px;

  ${props =>
    props.withBottomPadding &&
    `
    padding-bottom: 32px;
  `}

  ${props =>
    props.withTopPadding &&
    `
    padding-top: 32px;
  `}

  ${props =>
    (props.centerContentVertically || props.centerContentHorizontally) &&
    `
    display: flex;
    height: 100%;
  `}

  ${props =>
    props.centerContentVertically &&
    `
    justify-content: center;
  `}

  ${props =>
    props.centerContentHorizontally &&
    `
    align-items: center
  `}
`;

ContentSection.defaultProps = {
  withBottomPadding: true,
  withTopPadding: true,
};

export const ContentSectionFullHeight = styled(ContentSection)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const Footer = styled.div`
  padding: 24px;
  align-self: flex-bottom;
  border-top: 1px solid ${props => props.theme.colors.background.tertiary};
  height: auto;
`;
