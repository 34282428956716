import { Alert, Button, FlexCrate, Input, Text } from '@arcadiapower/shrike';
import { Organization, Provider, SmtMeterSubmission } from '@schema/schemas';
import { useEffect, useMemo } from 'react';
import { Control, UseControllerProps, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { parseErrorMessage } from '@client/config/errors';
import { trackEvent } from '@client/utils/analytics';
import { ContentSectionFullHeight, Footer } from '../connect-container';
import { Form } from '../form';
import { Disclaimer } from '../disclaimer';
import { OrganizationTerms } from '../organization-terms';

type InputProps = {
  fieldName: 'meterNumber' | 'retailCustomerEmail' | 'electricServiceIdentifier';
  control: Control<any>;
};

const ReactHookFormInput = ({ fieldName, control }: InputProps) => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.smtMeterForm' });
  const label = t(`${fieldName}`);

  const rules: UseControllerProps['rules'] = useMemo(() => {
    const result: UseControllerProps['rules'] = {};
    result.required = t('validations.fieldRequiredErrorMessage', { fieldName: label });
    if (fieldName === 'electricServiceIdentifier') {
      result.minLength = {
        value: 17,
        message: t('validations.fieldLengthErrorMessage'),
      };
      result.maxLength = {
        value: 22,
        message: t('validations.fieldLengthErrorMessage'),
      };
    } else if (fieldName === 'retailCustomerEmail') {
      result.pattern = {
        value:
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t('validations.fieldValidEmailErrorMessage'),
      };
    }
    return result;
  }, [label, t, fieldName]);

  const { field, fieldState } = useController({
    name: fieldName,
    control,
    rules,
  });

  useEffect(() => {
    if (fieldState.isDirty) {
      trackEvent(`meters-field-modified-${fieldName}`, {
        label,
        backendField: fieldName,
      });
    }
  }, [fieldState.isDirty, fieldName, label]);

  const minLength = fieldName === 'electricServiceIdentifier' ? 17 : 0;
  const maxLength = fieldName === 'electricServiceIdentifier' ? 22 : 255;
  return (
    <Input
      maxLength={maxLength}
      minLength={minLength}
      errorText={fieldState.error?.message}
      label={label}
      type="text"
      onChange={field.onChange}
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
    ></Input>
  );
};

export type Props = {
  onSubmit: (smtMeterFields: SmtMeterSubmission) => void;
  organization: Organization;
  provider: Provider;
  error?: unknown;
  pending: boolean;
};

export const SmtMeterForm = ({
  onSubmit,
  organization,
  provider,
  error,
  pending,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.smtMeterForm' });
  const { handleSubmit, control } = useForm<SmtMeterSubmission>({
    defaultValues: {
      retailCustomerEmail: '',
      electricServiceIdentifier: '',
      meterNumber: '',
    },
  });
  return (
    <>
      <ContentSectionFullHeight>
        <FlexCrate flexDirection="column" gap="32px">
          <Text textStyle="heading900" tag="h3" data-testid="providerName">
            {provider.name}
          </Text>
          <Text>{t('description', { providerName: provider.name })}</Text>
          <Form onSubmit={handleSubmit(data => onSubmit(data))} id="metersForm">
            <FlexCrate flexDirection="column" gap="16px">
              <ReactHookFormInput fieldName="retailCustomerEmail" control={control} />
              <ReactHookFormInput fieldName="meterNumber" control={control} />
              <ReactHookFormInput fieldName="electricServiceIdentifier" control={control} />
              {!!error && <Alert>{parseErrorMessage(error, '', true)}</Alert>}
            </FlexCrate>
          </Form>
          <Disclaimer />
        </FlexCrate>
      </ContentSectionFullHeight>
      <Footer>
        <OrganizationTerms organization={organization} margin={{ bottom: '24px' }} />
        <Button type="submit" loading={pending} fullWidth={true} form="metersForm" mode="primary">
          {t('submit')}
        </Button>
      </Footer>
    </>
  );
};
