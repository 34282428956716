import { Provider } from '@schema/schemas';
import { FlexCrate, Text, Link } from '@arcadiapower/shrike';
import error from '@client/assets/icons/error.svg';
import { useTranslation } from 'react-i18next';
import InlineSVG from 'react-inlinesvg';
import { ContentSectionFullHeight } from '../connect-container';

export type Props = {
  provider: Provider;
  onProviderReselect?: () => void;
};

export const MfaFailureScreen = ({ provider, onProviderReselect }: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.mfaFailure' });

  const getFailureText = (): string => {
    return t('error', { providerName: provider.name });
  };
  return (
    <ContentSectionFullHeight>
      <FlexCrate flexDirection="column" alignItems="center" margin="auto">
        <InlineSVG src={error} height="48px" width="48px" />
        <FlexCrate margin={{ top: '32px' }} flexDirection="column" alignItems="center" gap="32px">
          <Text textAlign="center" textStyle="paragraph600">
            {getFailureText()}
          </Text>
          <FlexCrate flexDirection="column" alignItems="center" gap="16px">
            {!!onProviderReselect && (
              <>
                <Text textAlign="center" textStyle="paragraph600">
                  {t('selectDescription')}
                </Text>
                <Link color="blue" onClick={onProviderReselect} openInNewTab={false} type="inline">
                  {t('reselectUtility')}
                </Link>
              </>
            )}
          </FlexCrate>
        </FlexCrate>
      </FlexCrate>
    </ContentSectionFullHeight>
  );
};
