import { FlexCrate, Icon } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { DisclaimerText, LockSpacer } from './disclaimer.style';

export const Disclaimer = (): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.disclaimer' });

  return (
    <FlexCrate gap="24px" flexDirection="column">
      <LockSpacer>
        <Icon icon="Lock" color="primary" />
      </LockSpacer>
      <DisclaimerText>{t('text')}</DisclaimerText>
    </FlexCrate>
  );
};
