/* import.meta.env does not support destructuring */
/* 
/* eslint-disable prefer-destructuring */
const checkEnvForBoolean = (value?: string) => value?.toUpperCase() === 'TRUE';

export const TRPC_URL = import.meta.env.ARCADIA_TRPC_URL ?? 'http://localhost:3000/api/trpc';
export const DEBUG_TRPC = checkEnvForBoolean(import.meta.env.ARCADIA_DEBUG_TRPC);
export const DEBUG_ANALYTICS = checkEnvForBoolean(import.meta.env.ARCADIA_DEBUG_ANALYTICS);

export const ENVIRONMENT = import.meta.env.MODE ?? 'development';

export const ENABLE_SENTRY = checkEnvForBoolean(import.meta.env.ARCADIA_ENABLE_SENTRY);
export const SENTRY_RELEASE = import.meta.env.ARCADIA_SENTRY_RELEASE;
export const SENTRY_DSN =
  import.meta.env.ARCADIA_SENTRY_DSN ?? 'https://examplePublicKey@o0.ingest.sentry.io/0';
